import React, { useContext } from "react";
import { AppContext } from "../App";

function Header() {
	const { setMenuVisible, list, shop, settings, setCategory, setSearchVisible } =
		useContext(AppContext);

	return (
		<>
			<div className="header">
				<div className="button">
					{shop && list && settings.menu.categories_menu_type == 1 && (
						<button onClick={() => setMenuVisible(true)}>
							<i className="fas fa-bars"></i>
						</button>
					)}
				</div>
				<button className="logo" onClick={() => setCategory(false)}>
					{settings.menu.logo && <img src={settings.menu.logo} />}
				</button>
				<div className="button">
					{shop && list && (
						<button onClick={() => setSearchVisible(true)}>
							<i className="fas fa-search"></i>
						</button>
					)}
				</div>
			</div>
			{list.description && list.description != "" && (
				<div className="subheader">{list.description}</div>
			)}
		</>
	);
}

export default Header;
