import React, { useContext, useEffect } from "react";

import Api from "./api/api";
import "../css/shops.css";

import { AppContext } from "../App";
import Label from "./Label";

function Shops() {
	const { setShop, shops, setShops, brand, setLockShop } = useContext(AppContext);

	useEffect(() => {
		load_shops();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const load_shops = async () => {
		let request = {
			orderby: "position",
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
			],
		};
		if (brand) {
			request.src.push({
				name: "brand_id",
				value: brand.id,
				compare: "equal",
				type: "join",
				join_table: "brands_shops_relations",
				join_field: "shop_id",
				src_field: "brand_id",
			});
		}
		const response = await Api.post("/shops/list/", request);
		setShops(response.rows);
		if (response.rows.length === 1) {
			setShop(response.rows[0]);
			setLockShop(true);
		}
	};

	return (
		<div className="main container-fluid shops-list">
			<h1>
				<Label number={2} />
			</h1>
			<div className="shops row">
				{shops.map((shop) => (
					<div
						key={shop.id}
						className="shop col-sm-6 col-md-4 col-xl-3"
						onClick={() => setShop(shop)}
					>
						<button>
							{shop.name}
							{<span>{shop.address + " " + shop.number + ", " + shop.city}</span>}
						</button>
					</div>
				))}
			</div>
		</div>
	);
}
export default Shops;
