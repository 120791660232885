import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";

function Label({ number }) {
	const { labels, lang } = useContext(AppContext);
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (labels) {
			let l = labels.filter((l) => {
				return l.id === number;
			});
			if (l.length > 0) setLabel(l[0][lang]);
		}
		// eslint-disable-next-line
	}, [labels, number, lang]);

	return label;
}

export default Label;
